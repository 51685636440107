<template>
  <div
    :style="getElementStyle"
    v-if="!loading && data.properties.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        v-if="!data.properties.hideLabel"
        :style="computedStyles"
      >
        <label
          for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide'"
          :style="getLabelStyles"
          >{{ label||data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col
        v-else
        :span="showLabel ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
      >
        <span v-if="hasLabel" class="transparentcol"></span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <template>
          <currency-input
            v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
            :placeholder="data.placeholder || data.label"
            v-model="form[data.key]"
            :currency="data.validations.currency"
            :locale="data.validations.locale"
            :suffix-icon="data.properties.el_icon"
            :style="getStyle"
            @blur="onInput"
            @change="applyFormRules($event)"
            class="el-input__inner"
            :disabled="isFromDocument ? false : checkReadonly()"
            :readonly="readonly()"
            :class="{
              'left-aligned-input': inputTextAlignment === 'left',
              'right-aligned-input': inputTextAlignment === 'right',
              'center-aligned-input': inputTextAlignment === 'center',
              'highlight-border': highlight,
            }"
          />

          <currency-input
            v-if="isList && !isView"
            :size="profilePage ? 'mini' : ''"
            :placeholder="data.placeholder || data.label"
            v-model="form[data.key]"
            :suffix-icon="data.properties.el_icon"
            :currency="data.validations.currency"
            :locale="data.validations.locale"
            :style="getStyle"
            @blur="onInput"
            @change="applyFormRules($event)"
            class="el-input__inner"
            :disabled="isFromDocument ? false : checkReadonly()"
            :readonly="readonly()"
            :class="{
              'left-aligned-input': textAlignment === 'left',
              'right-aligned-input': textAlignment === 'right',
              'center-aligned-input': textAlignment === 'center',
            }"
          />

          <p v-if="isView" :style="getStyle">
            <!-- {{ data.validations.currency }} -->
            <template v-if="form[data.key]">
              <span v-if="data.validations.currency === 'INR'">
                {{
                  Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                  }).format(form[data.key])
                }}
              </span>
              <span v-else-if="data.validations.currency === 'EUR'">
                {{
                  Intl.NumberFormat("en-IE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(form[data.key])
                }}</span
              >
              <span v-else-if="data.validations.currency === 'CAD'">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "CAD",
                  }).format(form[data.key])
                }}</span
              >
              <span v-else-if="data.validations.currency === 'AUD'">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "AUD",
                  }).format(form[data.key])
                }}
              </span>
              <span v-else>
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(form[data.key])
                }}
              </span>
            </template>
            <template v-else>
              <span v-if="data.validations.currency === 'INR'">
                {{
                  Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                  }).format(0)
                }}
              </span>
              <span v-else-if="data.validations.currency === 'EUR'">
                {{
                  Intl.NumberFormat("en-IE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(0)
                }}</span
              >
              <span v-else-if="data.validations.currency === 'CAD'">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "CAD",
                  }).format(0)
                }}</span
              >
              <span v-else-if="data.validations.currency === 'AUD'">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "AUD",
                  }).format(0)
                }}
              </span>
              <span v-else>
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(0)
                }}
              </span>
            </template>
          </p>
          <p v-if="hasError" style="color: red; font-size: 14px">
            {{ errorMsg }}
          </p>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import CurrencyInput from "./../../vue-weblineindia-currency-input/src/component";
import templateConstants from "../../../constants/lg-en/templates";
import { mapGetters } from "vuex";
export default {
  mixins: [userPermissionsHelper],
  components: {
    CurrencyInput,
  },
  props: [
    "data",
    "label",
    "country",
    "form",
    "isView",
    "hasLabel",
    "selectedEntityDataId",
    "isFromDocument",
    "checkIsDisabled",
    "profilePage",
    "colorFields",
    "highlight",
    "fromEntityViews",
    "isPayVariable",
    "isEntityVariable",
    "allFieldsData"

  ],
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    getStyle() {
      return `height:${this.data.height - 30}px; font-weight: 400`;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    inputTextAlignment() {
      return this.textAlignment ? this.textAlignment : "left"; // Set a default value if not provided
    },
  },
  data() {
    return {
      isList: false,
      showLabel: true,
      error: true,
      errorMsg: "",
      value: "",
      testvalue: 420,
      loading: false,
      hasError: false,
      textAlignment: "left",
      timeoutId: null,
    };
  },
  mounted() {
    this.loading = true;
    //this.defaultMethod();
    this.defaultValueUpdate();
    if (this.data.validations.locale === "nl-NL") {
      this.data.validations.locale = "en-IE";
    }
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if(this.isPayVariable)
    {
      // Assigning Currency Code From Pay Button 
      this.data.validations.currency = this.data.validations.updateRules.currency;
    }
    this.loading = false;
  },
  methods: {
    onInput() {
      this.applyDelay(() => {
        this.validateNumber();
        this.update();
      });
    },
    validateNumber() {
      let fieldValue = parseFloat(this.form[this.data.key]);
      let minValue = parseFloat(this.data.min_value);
      let maxValue = parseFloat(this.data.max_value);
      if (templateConstants.currencyPattern.test(this.form[this.data.key])) {
        if (this.data.max_value && this.data.min_value) {
          if (this.checkInBetween(fieldValue, maxValue, minValue)) {
            this.errorMsg = "";
            this.hasError = false;
          } else {
            this.form[this.data.key] = "";
            this.errorMsg =
              "Value should range between " +
              this.data.min_value +
              " and " +
              this.data.max_value;
            this.hasError = true;
          }
        } else {
          this.errorMsg = "";
          this.hasError = false;
        }
      } else {
        // this.errorMsg = 'Accepts positive and negative numbers with max two decimals.';
        this.hasError = true;
      }
    },
    applyDelay(callback) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        callback();
      }, 500);
    },
    checkInBetween(fieldValue, maxValue, minValue) {
      Number.prototype.between = function (a, b) {
        var min = Math.min(a, b),
          max = Math.max(a, b);

        return this >= min && this <= max;
      };
      return fieldValue.between(minValue, maxValue);
    },
    defaultValueUpdate(){
      if (
        this.data.default_value &&
        this.form &&
        this.data.key && !this.data.apply_default_at_end &&
        this.checkNumberIsEmpty(this.form[this.data.key], this.data)
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      }
    },
    defaultMethod() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        this.checkNumberIsEmpty(this.form[this.data.key], this.data)
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      } else if (
        this.checkNumberIsEmpty(this.form[this.data.key], this.data) &&
        typeof this.form[this.data.key] !== "number" &&
        this.data.default_mapped_field &&
        this.form[this.data.default_mapped_field]
      ) {
        this.$set(
          this.form,
          this.data.key,
          this.form[this.data.default_mapped_field]
        );
      }
      this.$emit("applyFormRules");
    },
    applyFormRules() {
      this.$emit("applyFormRules");
      if(this.data.default_mapped_field) {
        this.$emit("applyDefaultValues",this.allFieldsData,this.form,false)
      }
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "CURRENCY") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
          this.data.validations.currency = this.getGlobalVariableById.currency;
        }
      }
    },

    update(e) {
      console.log("e", e);

      this.form[this.data.key + "_currency"] = this.data.validations.currency;
      this.applyFormRules();
    },

    // checkReadonly() {
    //   if (this.data.styles && this.data.styles.labelStyle) {
    //     if (this.data.styles.labelStyle == "hide") {
    //       this.showLabel = false;
    //     }
    //     if (this.data.styles.labelStyle == "right") {
    //       this.isDefalutPos = false;
    //     }
    //   }
    //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
    // },
    readonly() {
      if (this.data.field_assignable === "read_only" && this.data.is_global_variable) {
    return true;
  }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
      }
      //If it's an entity variable, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
      //  }
    },
  },
  watch: {
    // $props: {
    //   handler() {
    //     // this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.textAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss">
.highlight-borde {
  border: 1px solid #ff4545;
}
.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px !important;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.left-aligned-input {
  text-align: left;
}

.right-aligned-input {
  text-align: right;
}

.center-aligned-input {
  text-align: center;
}

.error {
  color: red;
}
</style>
<style lang="scss" scoped>
.highlight-border{
  border-color: red !important;
}
.transparentcol {
  color: white;
  display: inline-block;
  height: 20px;
  opacity: 0;
}
</style>
